import React from "react"
import { Link } from "gatsby"

const Navigation = () => (
  <nav id="navigation">
    <Link to="/">Home</Link>
    <Link to="/about">About</Link>
    <Link to="/projects">Projects</Link>
    <Link to="/events">Events</Link>
    <Link to="/media">Media</Link>
  </nav>
)

export default Navigation
